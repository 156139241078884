import { render, staticRenderFns } from "./default.vue?vue&type=template&id=60074dcb&"
import script from "./default.vue?vue&type=script&lang=ts&"
export * from "./default.vue?vue&type=script&lang=ts&"
import style0 from "./default.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AnimateBodyBackground: require('/home/deploy/scenkonst-om-frontend-staging/releases/20240822130500/src/components/animate/BodyBackground/BodyBackground.vue').default,AnimateSiteReveal: require('/home/deploy/scenkonst-om-frontend-staging/releases/20240822130500/src/components/animate/SiteReveal/SiteReveal.vue').default,SiteAlert: require('/home/deploy/scenkonst-om-frontend-staging/releases/20240822130500/src/components/SiteAlert/SiteAlert.vue').default,SkipToContent: require('/home/deploy/scenkonst-om-frontend-staging/releases/20240822130500/src/components/misc/SkipToContent.vue').default,SiteHeader: require('/home/deploy/scenkonst-om-frontend-staging/releases/20240822130500/src/components/navigation/SiteHeader/SiteHeader.vue').default,MobileNavigation: require('/home/deploy/scenkonst-om-frontend-staging/releases/20240822130500/src/components/navigation/MobileNavigation/MobileNavigation.vue').default,SiteFooter: require('/home/deploy/scenkonst-om-frontend-staging/releases/20240822130500/src/components/navigation/SiteFooter/SiteFooter.vue').default,SiteSearch: require('/home/deploy/scenkonst-om-frontend-staging/releases/20240822130500/src/components/SiteSearch/SiteSearch.vue').default})
